<template>
    <inner-page-card heading="Users List" class="mt-3" icon="fa fa-user">

        <s-form class="filter-grid">

            <div class="pt-2 d-sm-none">
                <p class="font-inter-medium">Filters</p>
            </div>

            <validated-select class="ml-2 c-input-select-b order-3 order-lg-1" placeholder="Status"
                              v-model="filter.status" :options="statusOptions"/>

            <!--            <validated-select class="ml-2 c-input-select-b order-4 order-lg-2" placeholder="Role"-->
            <!--                              v-model="filter.role" :url="roleOptionsURL"/>-->

            <validated-input class="ml-2 order-1 order-lg-3" placeholder="Search" v-model="filter.ext_search">
                <template #append>
                    <i class="fa fa-search"></i>
                </template>
            </validated-input>

            <div class="ml-5 order-2 order-lg-4">
                <router-link to="/add-user/"
                             v-if="currentUser.is_superuser || currentUser.is_ventura_admin || currentUser.is_bank_admin || currentUser.permission.can_add_bank_user">
                    <btn class="ml-3" text="Add User" type="submit" size="" color="secondary"></btn>
                </router-link>
            </div>

        </s-form>
        <custom-vue-table class="d-sm-none c-vue-table" :fields="fields" :url="listUrl" :per-page="10" ref="table"
                          :show-search-box="false"
                          :extra-params="{
                    status: filter.status,
                    role:filter.role,
                    ext_search:filter.ext_search
                   }">

            <template slot="status" slot-scope="props">
                <activate-suspend-buttons :active="props.rowData.is_active"
                                          @activate="setIsActive(props.rowData, true)"
                                          @suspend="setIsActive(props.rowData, false)">
                </activate-suspend-buttons>
            </template>

            <template slot="actions" slot-scope="props">
                <div class="btn-group-tight">
                    <btn design="" icon="fa fa-eye"
                         class="border-gray-hover p-2 text-5"
                         @click="setView(props.rowData)"
                          v-if="canView"/>

                    <btn design="" icon="fa fa-pencil" class="border-gray-hover p-2 text-5"
                         @click="setEdit(props.rowData)"
                          v-if="canEdit"/>

                    <btn design="" icon="fa fa-trash-o" class="border-gray-hover p-2 text-5"
                         @click="deleteItem(props.rowData)"
                          v-if="canDelete"/>
                </div>
            </template>

        </custom-vue-table>

        <modal title="User Details" ref="UserModal" :no-close-on-backdrop="true" width="60r"
               header-color="primary">
            <ViewUser :item="editingItem" @back="formSuccess"/>
        </modal>

        <modal title="User Update" ref="UserEditModal" :no-close-on-backdrop="true" width="60r"
               header-color="primary">
            <edit-user :initial-data="editingItem" @success="formSuccess"/>
        </modal>

        <delete-modal ref="userDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the User <b v-html="deletingItem && deletingItem.name"></b>. Are you sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
            </template>
        </delete-modal>

        <users-list-mobile-view :user-list="true" @view="setView" @delete="deleteItem" @edit="setEdit"
                                @editBankUser="setEdit" :ext_search="filter.ext_search" :status="filter.status"
                                ref="userListMobileView" class="d-lg-none"></users-list-mobile-view>

    </inner-page-card>
</template>

<script>
import UsersListMobileView from '../../views/users/UsersListMobileView';
import ActivateSuspendButtons from '@components/manage-users/ui/ActivateSuspendButtons';
import EditUser from '../../views/users/EditUser';
import urls from '../../data/urls';
import ViewUser from './ViewUser';
import axios from 'secure-axios';
import { mapGetters } from 'vuex';

export default {
    name : 'UsersListPage',

    components : {
        UsersListMobileView,
        ViewUser,
        EditUser,
        ActivateSuspendButtons
    },

    computed : {
        ...mapGetters(['currentUser']),
        canView () {
            return () => {
                return this.currentUser.is_superuser ||
                    this.currentUser.is_ventura_admin ||
                    this.currentUser.is_bank_admin ||
                    this.currentUser.is_bank_user;
            };
        },
        canEdit () {
            return (i) => {
                return this.currentUser.is_superuser ||
                    this.currentUser.is_ventura_admin ||
                    this.currentUser.is_bank_admin ||
                    this.currentUser.is_bank_user ||
                    this.currentUser.permission.can_edit_bank_admin;
            };
        },
        canDelete () {
            return (i) => {
                return this.currentUser.is_superuser ||
                    this.currentUser.is_ventura_admin ||
                    this.currentUser.is_bank_admin ||
                    this.currentUser.is_bank_user ||
                    this.currentUser.permission.can_delete_bank_admin;
            };
        }
    },

    data () {
        return {
            deleteUrl    : urls.userList.delete,
            deletingItem : null,
            editingItem  : null,

            listUrl : urls.userList.list,

            filter : {
                status     : this.$route.params.status,
                role       : '',
                ext_search : ''
            },

            statusOptions : [
                { label : 'All', value : 'All' },
                { label : 'Active', value : 'True' },
                { label : 'Inactive', value : 'False' }
            ],
            roleOptionsURL : urls.userList.roleOptions,

            fields : [
                {
                    name  : 'name',
                    title : 'Name'
                },
                {
                    name  : 'email',
                    title : 'Email'
                },
                {
                    name  : 'admin_or_user',
                    title : 'Admin/User'
                },
                {
                    name  : '__slot:status',
                    title : 'Status'
                }

            ]
        };
    },
    beforeMount () {
        this.setUserFields();
    },
    methods : {
        setUserFields () {
            if (this.currentUser.is_ventura_admin ||
                this.currentUser.is_superuser ||
                this.currentUser.is_bank_admin ||
                this.currentUser.permission.can_edit_bank_user) {
                this.fields.push({
                    name       : '__slot:actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right',
                    title      : 'Actions'
                });
            }
        },
        setView (item) {
            this.$router.push({ path : '/user/' + item.id + '/details/' });
            // this.editingItem = { ...item };
            // this.$refs.UserModal.show();
        },

        formSuccess () {
            this.$refs.UserEditModal.close();
            this.$refs.UserModal.close();
            this.editingItem = null;
            this.$refs.table.refreshTable();
            this.$refs.userListMobileView.loadData();
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.UserEditModal.show();
        },

        deleteItem (item) {
            this.deletingItem = { ...item };
            this.$refs.userDeleteModal.show();
        },

        deleteComplete (response) {
            this.$refs.userDeleteModal.close();
            this.$notify('Successfully deleted', 'success',
                {
                    type : 'success'
                });
            this.$refs.table.refreshTable();
        },

        deleteFailure (json) {
            console.log(json);
            this.$notify(json.errors, 'Error',
                {
                    type : 'warning'
                }
            );
        },

        setIsActive (user, status) {
            user.loading = true;

            const permission = this.checkPermission(status);

            if (!permission) {
                alert('Don\'t have permission for this Action..!');
                return;
            }

            const model = {
                user   : user.id,
                status : status
            };
            const that = this;

            axios.form(urls.userList.statusUpdate, model).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    user.loading = false;
                    user.is_active = status;
                    that.$refs.table.refreshTable();
                } else {
                    user.loading = false;
                    user.is_active = !status;
                }
            }).catch(function (exception) {
                console.log('exception occurred : ', exception);
                user.is_active = !status;
            });
        },

        checkPermission (status) {
            if (status === true) {
                if (this.currentUser.is_superuser) {
                    return true;
                } else {
                    return this.currentUser.permission.can_accept_bank_user;
                }
            } else {
                if (this.currentUser.is_superuser) {
                    return true;
                } else {
                    return this.currentUser.permission.can_reject_bank_user;
                }
            }
        }
    }

};
</script>

<style scoped lang="scss">
.filter-grid {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;

    @media (min-width: 992px) {
        grid-template-columns:1fr repeat(2, 2fr) 3fr 2fr;
    }

}
</style>
