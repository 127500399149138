<template>
    <div class="p-4">
        <div class="card b-1 bs-4 py-5">

            <div class="col-12">
                <div class="fl-x fl-j-c mb-4">
                    <div style="width: 5rem;height: 5rem; border-radius: 50%"
                         class="bg-primary text-center fl-x-cc">
                        <span class="m-0">
                            <i class="fa fa-user fa-2x"></i>
                        </span>
                    </div>
                </div>
            </div>

            <div class="row">

                <div class="col-12">
                    <div class="tab-details-contain">
                        <div class="fl-x fl-j-e item-min-width">
                            <div class="fl-x fl-j-e">
                                <div class="data-label">Username </div>
                            </div>
                            <span class="px-3">:</span>
                            <div class="fl-x fl-j-s item-min-width pl-3">
                            <span class="">
                                <span class="font-inter-medium">{{ item.username }}</span>
                            </span>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="col-12">
                    <div class="tab-details-contain">
                        <div class="fl-x fl-j-e item-min-width">
                            <div class="fl-x fl-j-e">
                                <div class="data-label">Name</div>
                            </div>
                            <span class="px-3">:</span>
                            <div class="fl-x fl-j-s item-min-width pl-3">
                            <span class="">
                                <span class="font-inter-medium">{{ item.name }}</span>
                            </span>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="col-12">
                    <div class="tab-details-contain">
                        <div class="fl-x fl-j-e item-min-width ">
                            <div class="fl-x fl-j-e">
                                <div class="data-label">Email</div>
                            </div>
                            <span class="px-3">:</span>
                            <div class="fl-x fl-j-s item-min-width pl-3">
                            <span class="">
                                <span class="font-inter-medium">{{ item.email }}</span>
                            </span>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="col-12">
                    <div class="tab-details-contain">
                        <div class="fl-x fl-j-e item-min-width ">
                            <div class="fl-x fl-j-e item-min-width">
                                <div class="data-label">Mobile Number</div>
                            </div>
                            <span class="px-3">:</span>
                            <div class="fl-x fl-j-s item-min-width pl-3">
                            <span class="">
                                <span class="font-inter-medium">{{ item.mobile_number }}</span>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12" v-if="!item.is_customer">
                    <div class="tab-details-contain">
                        <div class="fl-x fl-j-e item-min-width ">
                            <div class="fl-x fl-j-e item-min-width">
                                <div class="data-label">Employee Number</div>
                            </div>
                            <span class="px-3">:</span>
                            <div class="fl-x fl-j-s item-min-width pl-3">
                            <span class="">
                                <span class="font-inter-medium">{{ item.employee_number }}</span>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12" v-if="!item.is_customer">
                    <div class="tab-details-contain">
                        <div class="fl-x fl-j-e item-min-width">
                            <div class="fl-x fl-j-e item-min-width">
                                <div class="data-label">Employee Role</div>
                            </div>
                            <span class="px-3">:</span>
                            <div class="fl-x fl-j-s item-min-width pl-3">
                            <span class="">
                                <span class="font-inter-medium">{{ item.employee_role }}</span>
                            </span>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="col-12" v-if="item.is_bank_admin || item.is_bank_user">
                    <div class="tab-details-contain">
                        <div class="fl-x fl-j-e item-min-width ">
                            <div class="fl-x fl-j-e item-min-width">
                                <div class="data-label">Bank</div>
                            </div>
                            <span class="px-3">:</span>
                            <div class="fl-x fl-j-s item-min-width pl-3">
                            <span class="">
                                <span class="font-inter-medium" v-if="item.bank">{{ item.bank.bank_name }}</span>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="tab-details-contain">
                        <div class="fl-x fl-j-e item-min-width ">
                            <div class="fl-x fl-j-e item-min-width">
                                <div class="data-label">User Status</div>
                            </div>
                            <span class="px-3">:</span>
                            <div class="fl-x fl-j-s item-min-width pl-3">
                            <span class="">
                                <span class="font-inter-medium">{{ item.status }}</span>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12" v-if="!item.is_customer">
                    <div class="fl-x fl-j-c mt-4">
                        <div class="h-100 w-50 text-white py-3 tag-item-bg  border-r-2 border-none fl-x-cc">
                            <span class="mr-3"><i class="fa fa-bullhorn fa-2x"></i></span>
                            <span class="mr-3 fs-lg-3 font-inter-medium">Created Auctions : </span>
                            <span class="fs-lg-5 font-inter-medium">{{ item.auction_created || 0 }}</span>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="fl-x fl-j-c mt-4">
                        <btn text="Back" color="secondary" class="px-5" @click="BackButton"></btn>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

export default {
    name : 'ViewUser',

    props : {
        item : {
            type : Object
        }
    },

    methods : {
        BackButton () {
            this.$emit('back');
        }
    }
};
</script>

<style scoped lang="scss">
.avatar-contain {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    border-radius: 50%
}

.cell-item {
    display: flex;
    justify-content: space-between;
    @media (min-width: 992px) {
        padding: 0.875rem 0.3125rem;
    }
    //border-left: 1px solid gray;
    //border-right: 1px solid gray;
    //border-top: 1px solid gray;
    position: relative;
    width: 50%;
}

.item-min-width {
    min-width: 50%;
}

.data-label {
    font-size: 17px;
    font-weight: 400;
    //display: inline-flex;
    //width: 70%;
    //min-width: 7rem;
}

.value-item b {
    font-size: 18px;
}

.tab-details-contain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tag-item-bg {
    background: url("../../assets/web/bg/header.png");
}
</style>
